import React, { useEffect, useState, useRef } from 'react';
import {
    Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, ThemeProvider, Container, AppBar, Toolbar, Typography, Grid, FormControlLabel, Checkbox,
} from '@mui/material';
import axios from 'axios';
import config from '../../services/config';
import ReactPaginate from 'react-paginate';
import { useTheme } from '@mui/material/styles';
import CustomAlert from '../common/CustomAlert';
import log from 'loglevel';

const Customer = ({ user }) => {
    const theme = useTheme();
    const [customers, setCustomers] = useState([]);
    const [visible, setVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const rowsPerPage = 10;
    log.setLevel('info');

    // State for alerts
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    // State for confirmation alert
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmType, setConfirmType] = useState(false);

    // Create refs for input fields
    const customerNameRef = useRef(null);
    const addressRef = useRef(null);
    const nicRef = useRef(null);
    const homeNoRef = useRef(null);
    const mobileNoRef = useRef(null);

    const [formData, setFormData] = useState({
        customerName: '',
        address: '',
        nic: '',
        homeNo: '',
        mobileNo: '',
        status: true,
    });

    const [updateFormData, setUpdateFormData] = useState({
        customerId: '',
        customerName: '',
        address: '',
        nic: '',
        homeNo: '',
        mobileNo: '',
        status: '',
    });

    useEffect(() => {
        fetchCustomers();
    }, []);

    const fetchCustomers = async () => {
        try {
            const response = await axios.get(`${config.API_BASE_URL}/customer/getallcustomers`);
            setCustomers(response.data);
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Error fetching customers:', error);
            }
            log.error('Error fetching customers:', error);
        }
    };

    const filteredCustomer = customers.filter(customer =>
        customer.customerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer.address.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        setPageCount(Math.ceil(filteredCustomer.length / rowsPerPage));
    }, [filteredCustomer]);

    useEffect(() => {
        setCurrentPage(0);
    }, [searchTerm]);

    const paginatedCustomers = filteredCustomer.slice(
        currentPage * rowsPerPage,
        (currentPage + 1) * rowsPerPage
    );

    const handlePageChange = (event) => {
        const selectedPage = event.selected;
        setCurrentPage(selectedPage);
    };

    const handleChange = (e) => {
        const { type, name, checked, value } = e.target;
        if (type === 'checkbox') {
            setFormData({ ...formData, [name]: checked });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const validateNIC = (nic) => {
        const oldNicPattern = /^[0-9]{9}[vVxX]$/;  // Old NIC format: 9 digits + V/X
        const newNicPattern = /^[0-9]{12}$/;       // New NIC format: 12 digits

        return oldNicPattern.test(nic) || newNicPattern.test(nic);
    };

    const handleAddCustomer = async () => {
        const { customerName, address, nic, homeNo, mobileNo } = formData;

        if (!customerName) {
            setAlertMessage('Please enter the Customer Name.');
            setAlertSeverity('error');
            setAlertOpen(true);
            customerNameRef.current.focus();
            return;
        }
        if (!address) {
            setAlertMessage('Please enter the Address.');
            setAlertSeverity('error');
            setAlertOpen(true);
            addressRef.current.focus();
            return;
        }
        if (nic.length > 0 && !validateNIC(nic)) {
            setAlertMessage('Please enter a valid NIC.');
            setAlertSeverity('error');
            setAlertOpen(true);
            nicRef.current.focus();
            return;
        }
        if (!homeNo) {
            setAlertMessage('Please enter the Home No.');
            setAlertSeverity('error');
            setAlertOpen(true);
            homeNoRef.current.focus();
            return;
        }

        if (homeNo.length < 10) {
            setAlertMessage('Please enter a valid home number.');
            setAlertSeverity('error');
            setAlertOpen(true);
            homeNoRef.current.focus();
            return;
        }

        if (mobileNo.length > 0 && mobileNo.length < 10) {
            setAlertMessage('Please enter a valid mobile number.');
            setAlertSeverity('error');
            setAlertOpen(true);
            mobileNoRef.current.focus();
            return;
        }

        setConfirmOpen(true);
        setConfirmType("N");

    };

    const handleConfirmAddCustomer = async () => {

        try {
            const response = await axios.post(`${config.API_BASE_URL}/customer/savecustomer`, { newCustomer: formData, sysUser: user[0].User_Name });

            setAlertMessage(response.data.message);
            setAlertSeverity(response.data.success ? 'success' : 'error');
            setAlertOpen(true);

            if (response.data.success) {
                setVisible(false);
                setFormData({
                    customerName: '',
                    address: '',
                    nic: '',
                    homeNo: '',
                    mobileNo: '',
                    status: true,
                });
                fetchCustomers();
            }
        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving item:', error);
            }
            log.error('Error saving item:', error);
        } finally {
            setConfirmOpen(false);
        }
    };



    const handleUpdateCustomer = async () => {
        const { customerName, address, nic, homeNo, mobileNo } = updateFormData;

        if (!customerName) {
            setAlertMessage('Please enter the Customer Name.');
            setAlertSeverity('error');
            setAlertOpen(true);
            customerNameRef.current.focus();
            return;
        }
        if (!address) {
            setAlertMessage('Please enter the Address.');
            setAlertSeverity('error');
            setAlertOpen(true);
            addressRef.current.focus();
            return;
        }
        if (nic.length > 0 && !validateNIC(nic)) {
            setAlertMessage('Please enter a valid NIC.');
            setAlertSeverity('error');
            setAlertOpen(true);
            nicRef.current.focus();
            return;
        }
        if (!homeNo) {
            setAlertMessage('Please enter the Home No.');
            setAlertSeverity('error');
            setAlertOpen(true);
            homeNoRef.current.focus();
            return;
        }

        if (homeNo.length < 10) {
            setAlertMessage('Please enter a valid home number.');
            setAlertSeverity('error');
            setAlertOpen(true);
            homeNoRef.current.focus();
            return;
        }

        if (mobileNo.length > 0 && mobileNo.length < 10) {
            setAlertMessage('Please enter a valid mobile number.');
            setAlertSeverity('error');
            setAlertOpen(true);
            mobileNoRef.current.focus();
            return;
        }

        setConfirmOpen(true);
        setConfirmType("U");
    };

    const handleConfirmUpdateCustomer = async () => {

        try {
            const response = await axios.post(`${config.API_BASE_URL}/customer/updatecustomer`, { updatedCustomer: updateFormData, sysUser: user[0].User_Name });

            setAlertMessage(response.data.message);
            setAlertSeverity(response.data.success ? 'success' : 'error');
            setAlertOpen(true);

            if (response.data.success) {
                setUpdateVisible(false);
                fetchCustomers();
            }
        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);

            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving item:', error);
            }
            log.error('Error saving item:', error);
        } finally {
            setConfirmOpen(false);
        }
    };

    const handleEditClick = (customer) => {
        setUpdateFormData({
            customerId: customer.customerId,
            customerName: customer.customerName,
            address: customer.address,
            nic: customer.nic,
            homeNo: customer.homeNo,
            mobileNo: customer.mobileNo,
            status: customer.status === "Active",
        });
        setUpdateVisible(true);
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg">
                <AppBar position="static" sx={{ bgcolor: 'primary.main', mb: 2 }}>
                    <Toolbar>
                        <Typography variant="h4" component="div" sx={{ flexGrow: 1, color: 'white' }}>
                            Customer Management
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={8}>
                        <TextField
                            variant="outlined"
                            label="Search"
                            fullWidth
                            onChange={(e) => setSearchTerm(e.target.value)}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'primary.main',
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => setVisible(true)}
                        >
                            Add New Customer
                        </Button>
                    </Grid>
                </Grid>

                <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                    <Table>
                        <TableHead sx={{ bgcolor: '#424242' }}>
                            <TableRow>
                                <TableCell style={{ color: 'white' }}>Customer Name</TableCell>
                                <TableCell style={{ color: 'white' }}>Address</TableCell>
                                <TableCell style={{ color: 'white' }}>NIC</TableCell>
                                <TableCell style={{ color: 'white' }}>Home No</TableCell>
                                <TableCell style={{ color: 'white' }}>Mobile No</TableCell>
                                <TableCell style={{ color: 'white' }}>Status</TableCell>
                                <TableCell style={{ color: 'white' }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedCustomers.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                                        No data available
                                    </TableCell>
                                </TableRow>
                            ) : (
                                paginatedCustomers.map((customer) => (
                                    <TableRow key={customer.customerId} hover>
                                        <TableCell>{customer.customerName}</TableCell>
                                        <TableCell>{customer.address}</TableCell>
                                        <TableCell>{customer.nic}</TableCell>
                                        <TableCell>{customer.homeNo}</TableCell>
                                        <TableCell>{customer.mobileNo}</TableCell>
                                        <TableCell>{customer.status}</TableCell>
                                        <TableCell>
                                            <Button variant="outlined" color="primary" onClick={() => handleEditClick(customer)}>
                                                Edit
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Pagination */}
                {paginatedCustomers.length > 0 && (
                    <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        forcePage={currentPage}
                        style={{ marginTop: '20px' }}
                    />
                )}

                {/* Dialogs */}
                <Dialog open={visible} onClose={() => setVisible(false)}>
                    <DialogTitle>Add New Customer</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                <TextField
                                    inputRef={customerNameRef}
                                    name="customerName"
                                    label="Customer Name"
                                    value={formData.customerName}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                <TextField
                                    inputRef={addressRef}
                                    name="address"
                                    label="Address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    inputRef={nicRef}
                                    name="nic"
                                    label="NIC"
                                    value={formData.nic}
                                    onChange={handleChange}
                                    fullWidth
                                    inputProps={{ maxLength: 12 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    inputRef={homeNoRef}
                                    name="homeNo"
                                    label="Home No"
                                    value={formData.homeNo}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d{0,10}$/.test(value)) {
                                            handleChange(e);
                                        }
                                    }}
                                    fullWidth
                                    required
                                    inputProps={{ maxLength: 10 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <TextField
                                    inputRef={mobileNoRef}
                                    name="mobileNo"
                                    label="Mobile No"
                                    value={formData.mobileNo}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d{0,10}$/.test(value)) {
                                            handleChange(e);
                                        }
                                    }}
                                    fullWidth
                                    inputProps={{ maxLength: 10 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                    label="Status"
                                    labelPlacement="start"
                                    control={
                                        <Checkbox
                                            checked={formData.status} // Replace with your state variable
                                            onChange={handleChange} // Replace with your checkbox change handler
                                            name="status" // Replace with your checkbox name
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setVisible(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleAddCustomer} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Update Item Dialog */}
                <Dialog open={updateVisible} onClose={() => setUpdateVisible(false)}>
                    <DialogTitle>Update Customer Details</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                <TextField
                                    inputRef={customerNameRef}
                                    name="customerName"
                                    label="Customer Name"
                                    value={updateFormData.customerName}
                                    onChange={(e) => setUpdateFormData({ ...updateFormData, customerName: e.target.value })}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                <TextField
                                    inputRef={addressRef}
                                    name="address"
                                    label="Address"
                                    value={updateFormData.address}
                                    onChange={(e) => setUpdateFormData({ ...updateFormData, address: e.target.value })}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    inputRef={nicRef}
                                    name="nic"
                                    label="NIC"
                                    value={updateFormData.nic}
                                    onChange={(e) => setUpdateFormData({ ...updateFormData, nic: e.target.value })}
                                    fullWidth
                                    inputProps={{ maxLength: 12 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    inputRef={homeNoRef}
                                    name="homeNo"
                                    label="Home No"
                                    value={updateFormData.homeNo}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d{0,10}$/.test(value)) {
                                            setUpdateFormData({ ...updateFormData, homeNo: e.target.value })
                                        }
                                    }}
                                    fullWidth
                                    required
                                    inputProps={{ maxLength: 10 }}

                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    inputRef={mobileNoRef}
                                    name="mobileNo"
                                    label="Mobile No"
                                    value={updateFormData.mobileNo}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d{0,10}$/.test(value)) {
                                            setUpdateFormData({ ...updateFormData, mobileNo: e.target.value })
                                        }
                                    }}
                                    fullWidth
                                    inputProps={{ maxLength: 10 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                    label="Status"
                                    labelPlacement="start"
                                    control={
                                        <Checkbox
                                            checked={updateFormData.status} // Replace with your state variable
                                            onChange={(e) => setUpdateFormData({ ...updateFormData, status: e.target.checked })}
                                            name="status" // Replace with your checkbox name
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setUpdateVisible(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleUpdateCustomer} color="primary">
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Custom Alert */}
                <CustomAlert
                    open={alertOpen}
                    onClose={() => setAlertOpen(false)}
                    message={alertMessage}
                    severity={alertSeverity}
                />

                {/* Confirmation Alert */}
                <CustomAlert
                    open={confirmOpen}
                    onClose={() => setConfirmOpen(false)}
                    message={confirmType === 'N' ? "Do you want to add this customer?" :
                        confirmType === 'U' ? "Do you want to update this customer?" : ""}
                    severity="warning"
                    onConfirm={confirmType === 'N' ? handleConfirmAddCustomer :
                        confirmType === 'U' ? handleConfirmUpdateCustomer : null}
                    isConfirmation
                />

            </Container>
        </ThemeProvider>
    );
};

export default Customer;
