import React, { useState, useEffect, useRef } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Grid,
} from '@mui/material';
import axios from 'axios';
import config from '../../services/config';
import CustomAlert from '../common/CustomAlert';
import log from 'loglevel';

const ReturnWasteDialog = ({ open, onClose, branchStockId, itemBatchId, itemCode, branchId, availableQty, buyingPrice, sellingPrice, user, onSave }) => {
    const [reasons, setReasons] = useState([]);
    const [selectedReason, setSelectedReason] = useState('');
    const [qty, setQty] = useState(0);
    log.setLevel('info');

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    // State for confirmation alert
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmType, setConfirmType] = useState(false);

    // Create refs for input fields
    const reasonRef = useRef(null);
    const qtyRef = useRef(null);

    // Fetch reasons from the database
    useEffect(() => {
        if (open) {
            axios.get(`${config.API_BASE_URL}/stock/getreasonsforreturnwaste`).then((response) => {
                setReasons(response.data);
            });
        }
    }, [open]);

    const handleSave = async () => {

        if (!selectedReason) {
            setAlertMessage('Please select a Reason.');
            setAlertSeverity('error');
            setAlertOpen(true);
            reasonRef.current.focus();
            return;
        }
        if (qty <= 0) {
            setAlertMessage('Please enter a quantity greater than 0.');
            setAlertSeverity('error');
            setAlertOpen(true);
            qtyRef.current.focus();
            return;
        }
        if (qty > availableQty) {
            setAlertMessage(`Please ensure the quantity does not exceed the available quantity (${availableQty}).`);
            setAlertSeverity('error');
            setAlertOpen(true);
            qtyRef.current.focus();
            return;
        }

        setConfirmOpen(true);
        setConfirmType("S");
    };

    const handleConfirmSave = async () => {

        const data = {
            branchStockId,
            itemBatchId,
            itemCode,
            branchId,
            reasonId: selectedReason,
            qty,
            buyingPrice,
            sellingPrice,
            sysUser: user[0].User_Name,
        };

        try {
            const response = await axios.post(`${config.API_BASE_URL}/stock/savereturnwaste`, { data: data });

            setAlertMessage(response.data.message);
            setAlertSeverity(response.data.success ? 'success' : 'error');
            setAlertOpen(true);

            if (response.data.success) {
                onSave();
                onClose();
            }
        } catch (error) {
            if (error.response) {
                setAlertMessage(error.response.data.message);
                setAlertSeverity('error');
            } else {
                setAlertMessage("Network error. Please check your connection.");
                setAlertSeverity('error');
            }
            setAlertOpen(true);
            if (process.env.NODE_ENV === 'development') {
                console.error('Error saving the return:', error);
            }
            log.error('Error saving the return:', error);
        } finally {
            setConfirmOpen(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Return/Waste</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {/* Reason Select Box */}
                    <Grid item xs={12} sx={{ mt: 1 }}>
                        <FormControl fullWidth variant="outlined" required>
                            <InputLabel id="reason-select-label">Reason</InputLabel>
                            <Select
                                labelId="reason-select-label"
                                value={selectedReason}
                                onChange={(e) => setSelectedReason(e.target.value)}
                                label="Reason"
                                inputRef={reasonRef}
                            >
                                {reasons.map((reason) => (
                                    <MenuItem key={reason.reasonId} value={reason.reasonId}>
                                        {reason.reasonName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Quantity Field */}
                    <Grid item xs={12}>
                        <TextField
                            name="qty"
                            label={`Quantity (Max: ${availableQty})`}
                            type="text"
                            value={qty}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                    setQty(e.target.value);
                                }
                            }}
                            fullWidth
                            required
                            inputRef={qtyRef}
                            onBlur={() => { if (!qty) { setQty(0); } }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>

            {/* Custom Alert */}
            <CustomAlert
                open={alertOpen}
                onClose={() => setAlertOpen(false)}
                message={alertMessage}
                severity={alertSeverity}
            />

            {/* Confirmation Alert */}
            <CustomAlert
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                message={confirmType === 'S' ? "Do you want to save this return?" : ""}
                severity="warning"
                onConfirm={confirmType === 'S' ? handleConfirmSave : null}
                isConfirmation
            />

        </Dialog>
    );
};

export default ReturnWasteDialog;
