import React from 'react';
import {
    Container,
    Typography,
    Box,
    Paper,
    Divider,
} from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';

const Payment = () => {
    return (
        <Container maxWidth="md" sx={{ mt: 8 }}>
            <Paper elevation={3} sx={{ p: 4, textAlign: 'center' }}>
                <Box display="flex" justifyContent="center" mb={3}>
                    <ConstructionIcon color="primary" sx={{ fontSize: 100 }} />
                </Box>

                <Typography variant="h4" gutterBottom>
                    Payment Page is Under Construction
                </Typography>
                <Divider sx={{ my: 2 }} />

                <Typography variant="body1" color="text.secondary" gutterBottom>
                    This page is currently under construction. We are working hard to make it available soon. Thank you for your patience!
                </Typography>

            </Paper>
        </Container>
    );
};

export default Payment;
