import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/User/Login';
import Home from './components/Layout/Home';
import ProtectedRoute from './components/common/ProtectedRoute';
import NotFound from './components/common/NotFound';
import { NotificationProvider } from './components/common/NotificationContext'; // Import NotificationProvider
import Notification from './components/common/Notification'; // Import Notification component

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <NotificationProvider> {/* Wrap everything with NotificationProvider */}
    <BrowserRouter>
      <Notification /> {/* Include the Notification component */}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </NotificationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
