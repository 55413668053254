// NotificationContext.js
import React, { createContext, useContext, useState } from 'react';

const NotificationContext = createContext();

export const useNotification = () => {
    return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [confirmations, setConfirmations] = useState([]);

    const addNotification = (message, type) => {
        setNotifications((prev) => [...prev, { message, type }]);
        setTimeout(() => {
            setNotifications((prev) => prev.filter((n) => n.message !== message));
        }, 3000);
    };

    const showConfirmation = (message, onConfirm, onCancel) => {
        setConfirmations((prev) => [...prev, { message, onConfirm, onCancel }]);
    };

    const confirmAction = (index) => {
        confirmations[index].onConfirm();
        setConfirmations((prev) => prev.filter((_, i) => i !== index));
    };

    const cancelAction = (index) => {
        confirmations[index].onCancel();
        setConfirmations((prev) => prev.filter((_, i) => i !== index));
    };

    const value = {
        addNotification,
        showConfirmation,
        notifications,
        confirmations,
        confirmAction,
        cancelAction,
    };

    return (
        <NotificationContext.Provider value={value}>
            {children}
        </NotificationContext.Provider>
    );
};
