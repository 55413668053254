import React, { useState, useEffect, useRef } from "react";
import {
    Container,
    Box,
    Typography,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Link,
} from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import config from '../../services/config';
import CustomAlert from '../common/CustomAlert';
import log from 'loglevel';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="#">
                Lap Tech
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'} All Rights Reserved
        </Typography>
    );
}

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [branchId, setBranchId] = useState("");
    const [branchName, setBranchName] = useState("");
    const [branches, setBranches] = useState([]);

    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("error");

    const navigate = useNavigate();

    const theme = createTheme({
        palette: {
            primary: {
                main: '#4caf50',
            },
        },
    });

    const usernameRef = useRef(null);
    const passwordRef = useRef(null);
    const branchRef = useRef(null);

    log.setLevel('info');

    useEffect(() => {
        const fetchBranches = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/branch/getbranches`);
                setBranches(response.data);
            } catch (error) {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error fetching branches:', error);
                }
                log.error('Error fetching branches:', error);
            }
        };

        fetchBranches();
    }, []);

    // Focus the username input when the component mounts
    useEffect(() => {
        if (usernameRef.current) {
            usernameRef.current.focus();
        }
    }, []);

    const handleBranchChange = (event) => {
        const selectedBranchId = event.target.value;
        setBranchId(selectedBranchId);
        const selectedBranch = branches.find(branch => branch.branchId === selectedBranchId);
        setBranchName(selectedBranch ? selectedBranch.branchName : "");
    };

    async function getDeviceFingerprint() {
        const fpPromise = FingerprintJS.load();
        const fp = await fpPromise;
        const result = await fp.get();
        return result.visitorId; // Unique device fingerprint
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const deviceFingerprint = await getDeviceFingerprint();

        console.log("deviceFingerprint " + deviceFingerprint);

        if (!username) {
            setAlertMessage("Please enter the Username.");
            setAlertSeverity("error");
            setOpenAlert(true);
            usernameRef.current.focus(); // Focus username field
            return;
        }

        if (!password) {
            setAlertMessage("Please enter the Password.");
            setAlertSeverity("error");
            setOpenAlert(true);
            passwordRef.current.focus(); // Focus password field
            return;
        }

        if (!branchId) {
            setAlertMessage("Please select a Branch.");
            setAlertSeverity("error");
            setOpenAlert(true);
            branchRef.current.focus(); // Focus branch field
            return;
        }



        try {
            const response = await axios.post(`${config.API_BASE_URL}/user/loginuser`, {
                username,
                password,
                branchId,
            });

            if (response.data.success) {
                localStorage.setItem('authToken', response.data.token);
                navigate('/home', { state: { user: response.data.user, branchId, branchName } });
            } else {
                setAlertMessage(response.data.message);
                setAlertSeverity("error");
                setOpenAlert(true);
            }
        } catch (error) {
            const errorMessage = error.response ? error.response.data.message : "Network error. Please check your connection.";
            setAlertMessage(errorMessage);
            setAlertSeverity("error");
            setOpenAlert(true);
        }
    };

    const handleAlertClose = () => {
        setOpenAlert(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 6,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <img alt="Company Logo" sx={{ m: 1, width: 80, height: 80 }} src="/Logo_Small.png" />
                    <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            value={username}
                            onChange={(e) => setUsername(e.target.value.trim())}
                            inputRef={usernameRef}
                            InputProps={{
                                style: { color: '#4caf50' },
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value.trim())}
                            inputRef={passwordRef} // Attach the ref here
                            InputProps={{
                                style: { color: '#4caf50' },
                            }}
                        />
                        <FormControl fullWidth margin="normal" required>
                            <InputLabel id="branch-label">Branch</InputLabel>
                            <Select
                                labelId="branch-label"
                                id="branch"
                                value={branchId}
                                label="Branch"
                                onChange={handleBranchChange}
                                inputRef={branchRef} // Attach the ref here
                                sx={{ '& .MuiSelect-root': { color: '#4caf50' } }}
                            >
                                {branches.map((branch) => (
                                    <MenuItem key={branch.branchId} value={branch.branchId}>
                                        {branch.branchName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Login
                        </Button>
                    </Box>
                </Box>

                <CustomAlert
                    open={openAlert}
                    onClose={handleAlertClose}
                    message={alertMessage}
                    severity={alertSeverity}
                />

                <Box mt={5}>
                    <Copyright />
                    <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 2 }}>
                        Version 1.0.0
                    </Typography>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default Login;
