// Notification.js
import React from 'react';
import { Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { useNotification } from './NotificationContext';

const Notification = () => {
    const { notifications, confirmations, confirmAction, cancelAction } = useNotification();

    return (
        <>
            {notifications.map((notification, index) => (
                <Snackbar
                    key={index}
                    open={true}
                    autoHideDuration={3000}
                    onClose={() => {}}
                >
                    <Alert severity={notification.type}>
                        {notification.message}
                    </Alert>
                </Snackbar>
            ))}

            {confirmations.map((confirmation, index) => (
                <Dialog
                    key={index}
                    open={true}
                    onClose={() => cancelAction(index)}
                >
                    <DialogTitle>Confirmation</DialogTitle>
                    <DialogContent>
                        {confirmation.message}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => cancelAction(index)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => confirmAction(index)} color="primary">
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            ))}
        </>
    );
};

export default Notification;
