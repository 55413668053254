import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Button,
} from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
  Home as HomeIcon,
  Receipt,
  Store,
  Payment as PaymentIcon,
  Replay,
  Inventory,
  Assessment,
  Person,
  ExitToApp,
  SwapHoriz,
  Inbox,
  LocalShipping,
  Group,
} from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Import the components to render
import Dashboard from './Dashboard';
import Invoice from '../Invoice/Invoice';
import StockBranch from '../Stock/StockBranch';
import StockAll from '../Stock/StockAll';
import Grn from '../GRN/Grn';
import TransferNew from '../Transfer/TransferNew';
import TransferApprove from '../Transfer/TransferApprove';
import Payment from '../Payment/Payment';
import Return from '../ReturnWaste/Return';
import Item from '../Item/Item';
import Reports from '../Report/Reports';
import User from '../User/User';
import Supplier from '../Supplier/Supplier';
import Customer from '../Customer/Customer';
import { useLocation } from 'react-router-dom';
import checkuserbranchnull from '../common/checkuserbranchnull';

// Create a theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#4caf50',
    },
    secondary: {
      main: '#f50057',
    },
  },
});

const Home = () => {
  const location = useLocation();
  const { user, branchId, branchName } = location.state || {};
  const [activeComponent, setActiveComponent] = useState('dashboard');
  const [openSubMenu, setOpenSubMenu] = useState({});

  // Toggle submenu
  const toggleSubMenu = (menu) => {
    setOpenSubMenu((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
  };

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('authToken');
    window.location.href = '/'; // Navigates to login page
  };

  // Function to render the selected component
  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 'dashboard':
        return <Dashboard />;
      case 'invoice':
        return <Invoice user={user} branchId={branchId} />;
      case 'branchstock':
        return <StockBranch user={user} branchId={branchId} />;
      case 'allstock':
        return <StockAll user={user} branchId={branchId} />;
      case 'grn':
        return <Grn user={user} />;
      case 'newtransfer':
        return <TransferNew user={user} branchId={branchId} />;
      case 'approvetransfer':
        return <TransferApprove user={user} branchId={branchId} />;
      case 'payment':
        return <Payment user={user} branchId={branchId} />;
      case 'return':
        return <Return user={user} branchId={branchId} />;
      case 'item':
        return <Item user={user} />;
      case 'reports':
        return <Reports />;
      case 'user':
        return <User user={user} />;
      case 'supplier':
        return <Supplier user={user} />;
      case 'customer':
        return <Customer user={user} />;
      default:
        return <Dashboard />;
    }
  };

  const isUserTypeUser = user && user[0].User_Type === 'User';
  const hideGrnAndItem = branchId !== 1 && user && user[0].User_Type !== 'User';

  // New function to handle component change
  const handleComponentChange = (component, userData) => {
    setActiveComponent(component);
    // If needed, handle the userData here
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        {/* AppBar with the company logo and user info */}
        <AppBar position="static">
          <Toolbar>
            <img src="/Logo_Small.png" alt="Company Logo" style={{ height: '40px', marginRight: '10px' }} />
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {branchName ? branchName : ''}
            </Typography>
            <Typography variant="body1" sx={{ marginRight: 2 }}>
              {user ? `User: ${user[0].User_Name}` : 'User'}
            </Typography>
          </Toolbar>
        </AppBar>

        <Grid container sx={{ mt: 2 }} spacing={2}>
          {/* Sidebar Menu */}
          <Grid item xs={12} md={2}>
            <Box
              sx={{
                bgcolor: 'background.paper',
                borderRadius: 1,
                boxShadow: 1,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: 2,
                margin: 0,
              }}
            >
              <List>
                {/* Dashboard Menu Item */}
                <ListItem
                  button
                  onClick={() => setActiveComponent('dashboard')}
                  sx={{ '&:hover': { cursor: 'pointer', bgcolor: 'primary.light' } }}
                >
                  <HomeIcon />
                  <ListItemText primary="Dashboard" />
                </ListItem>

                {/* Invoice Menu Item */}
                <ListItem
                  button
                  onClick={() => setActiveComponent('invoice')}
                  sx={{ '&:hover': { cursor: 'pointer', bgcolor: 'primary.light' } }}
                >
                  <Receipt />
                  <ListItemText primary="Invoice" />
                </ListItem>

                {/* Stock Menu with Submenu */}
                <ListItem
                  button
                  onClick={() => toggleSubMenu('stock')}
                  sx={{ '&:hover': { cursor: 'pointer', bgcolor: 'primary.light' } }}
                >
                  <Store />
                  <ListItemText primary="Stock" />
                  {openSubMenu.stock ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openSubMenu.stock} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      onClick={() => setActiveComponent('branchstock')}
                      sx={{ pl: 4, '&:hover': { cursor: 'pointer', bgcolor: 'primary.light' } }}
                    >
                      <ListItemText primary="Branch Stock" />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => setActiveComponent('allstock')}
                      sx={{ pl: 4, '&:hover': { cursor: 'pointer', bgcolor: 'primary.light' } }}
                    >
                      <ListItemText primary="All Stock" />
                    </ListItem>
                  </List>
                </Collapse>

                {/* Conditionally render GRN and Stock Transfer menus */}
                {!isUserTypeUser && !hideGrnAndItem && (
                  <>
                    {/* GRN Menu Item */}
                    <ListItem
                      button
                      onClick={() => setActiveComponent('grn')}
                      sx={{ '&:hover': { cursor: 'pointer', bgcolor: 'primary.light' } }}
                    >
                      <Inbox />
                      <ListItemText primary="GRN" />
                    </ListItem>
                  </>
                )}

                {!isUserTypeUser && (
                  <>
                    {/* Transfer Menu with Submenu */}
                    <ListItem
                      button
                      onClick={() => toggleSubMenu('transfer')}
                      sx={{ '&:hover': { cursor: 'pointer', bgcolor: 'primary.light' } }}
                    >
                      <SwapHoriz />
                      <ListItemText primary="Transfer" />
                      {openSubMenu.transfer ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openSubMenu.transfer} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          onClick={() => setActiveComponent('newtransfer')}
                          sx={{ pl: 4, '&:hover': { cursor: 'pointer', bgcolor: 'primary.light' } }}
                        >
                          <ListItemText primary="New Transfer" />
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => setActiveComponent('approvetransfer')}
                          sx={{ pl: 4, '&:hover': { cursor: 'pointer', bgcolor: 'primary.light' } }}
                        >
                          <ListItemText primary="Approve Transfer" />
                        </ListItem>
                      </List>
                    </Collapse>
                  </>
                )}

                {/* Payment Menu Item */}
                <ListItem
                  button
                  onClick={() => setActiveComponent('payment')}
                  sx={{ '&:hover': { cursor: 'pointer', bgcolor: 'primary.light' } }}
                >
                  <PaymentIcon />
                  <ListItemText primary="Payment" />
                </ListItem>

                {/* Return Menu Item */}
                <ListItem
                  button
                  onClick={() => setActiveComponent('return')}
                  sx={{ '&:hover': { cursor: 'pointer', bgcolor: 'primary.light' } }}
                >
                  <Replay />
                  <ListItemText primary="Return" />
                </ListItem>

                {!isUserTypeUser && !hideGrnAndItem && (
                  <>
                    {/* Item Menu Item */}
                    <ListItem
                      button
                      onClick={() => handleComponentChange('item', user)} // Pass user to Item
                      sx={{ '&:hover': { cursor: 'pointer', bgcolor: 'primary.light' } }}
                    >
                      <Inventory />
                      <ListItemText primary="Item" />
                    </ListItem>
                  </>
                )}

                {!isUserTypeUser && (
                  <>
                    {/* Reports Menu Item */}
                    <ListItem
                      button
                      onClick={() => setActiveComponent('reports')}
                      sx={{ '&:hover': { cursor: 'pointer', bgcolor: 'primary.light' } }}
                    >
                      <Assessment />
                      <ListItemText primary="Reports" />
                    </ListItem>
                  </>
                )}

                <ListItem
                  button
                  onClick={() => setActiveComponent('user')}
                  sx={{ '&:hover': { cursor: 'pointer', bgcolor: 'primary.light' } }}
                >
                  <Person />
                  <ListItemText primary="User" />
                </ListItem>

                {!isUserTypeUser && !hideGrnAndItem && (
                  <>
                    <ListItem
                      button
                      onClick={() => handleComponentChange('supplier', user)}
                      sx={{ '&:hover': { cursor: 'pointer', bgcolor: 'primary.light' } }}
                    >
                      <LocalShipping />
                      <ListItemText primary="Supplier" />
                    </ListItem>
                  </>
                )}

                <ListItem
                  button
                  onClick={() => setActiveComponent('customer')}
                  sx={{ '&:hover': { cursor: 'pointer', bgcolor: 'primary.light' } }}
                >
                  <Group />
                  <ListItemText primary="Customer" />
                </ListItem>

              </List>

              <Box sx={{ mt: 'auto', textAlign: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLogout}
                  startIcon={<ExitToApp />}
                  sx={{
                    width: '100%',
                    '&:hover': { cursor: 'pointer', bgcolor: 'primary.dark' },
                  }}
                >
                  Logout
                </Button>
              </Box>
            </Box>
          </Grid>

          {/* Main Content */}
          <Grid item xs={12} md={10}>
            <Box sx={{ p: 2, borderRadius: 1, bgcolor: 'background.default', boxShadow: 1, height: '100%' }}>
              {renderActiveComponent()}
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ bgcolor: 'primary.main', color: 'white', p: 2, mt: 4, textAlign: 'center' }}>
          <Typography variant="body2">
            Version 1.0.0 | Copyright © {new Date().getFullYear()} Lap Tech. All Rights Reserved.
          </Typography>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default checkuserbranchnull(Home);
